import { useEffect, useState } from "react";
import Post from "../../components/Post";
import style from "./style.module.css"

const IndexPage = () => {
    const [posts, setPosts] = useState([]);
    const apiUrl = process.env.REACT_APP_HTTP

    useEffect(() => {
        getPost()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPost = async () => {
        await fetch(`${apiUrl}/posts`).then(response => {
            response.json().then(post => {
                if (post.length === 0) {
                    setPosts([])
                } else {
                    setPosts(post)
                }
            })
        })
    };

    if (posts.length === 0) return 'Ուպս այստեղ դեռ նորություններ չկան։Նորություններ ավելացնելու համար նախ պետք է գրանցվել🙂։'
    return (
        <div>
            <p className={style.pp}><b>Փարաքարը որպես հնագիտական ֆենոմեն</b></p>
            {posts.length > 0 && posts?.map((post, index) => {
                return <Post {...post} key={index} />
            })}
        </div>
    )
}
export default IndexPage